const IMAGES = {
  // LOGO
  logo: require("../img/logo.png"),
  banner1: require("../img/home/banner1.png"),
  banner2: require("../img/home/banner2.png"),
  banner3: require("../img/home/banner3.png"),
  banner4: require("../img/home/banner4.png"),
  banner5: require("../img/home/banner5.png"),
};

export default IMAGES;
